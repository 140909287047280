import React, { useState } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import Login from './Login';
import Dashboard from './Dashboard';
import './App.css';

function App() {
    const [username, setUsername] = useState('');
    const [token, setToken] = useState('');
    const navigate = useNavigate();

    const login = async (e, username, password) => {
        e.preventDefault();
        const response = await fetch('https://my-auth-worker.info-1fa.workers.dev/login', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ username, password })
        });

        const data = await response.json();
        if (data.token) {
            localStorage.setItem('authToken', data.token);
            setUsername(username);
            setToken(data.token);
            navigate('/dashboard');
        } else {
            alert('Login failed: ' + data.error);
        }
    };

    return (
        <div className="container">
            <h1>Auth System</h1>
            <Routes>
                <Route path="/login" element={<Login login={login} />} />
                <Route path="/dashboard" element={<Dashboard username={username} token={token} />} />
                <Route path="/" element={<Login login={login} />} />
            </Routes>
        </div>
    );
}

export default App;
