import React, { useState } from 'react';

function Login({ login }) {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    return (
        <div>
            <h2>Login</h2>
            <form onSubmit={(e) => login(e, username, password)}>
                <label>
                    Username:
                    <input type="text" value={username} onChange={(e) => setUsername(e.target.value)} required />
                </label>
                <label>
                    Password:
                    <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} required />
                </label>
                <button className="orange-button" type="submit">Login</button>
            </form>
        </div>
    );
}

export default Login;
