import React, { useEffect, useState } from 'react';

function Dashboard({ username, token }) {
    const [balance, setBalance] = useState(0);
    const [keys, setKeys] = useState([]);
    const [totalKeys, setTotalKeys] = useState(0);

    useEffect(() => {
        const fetchData = async () => {
            const response = await fetch('https://my-auth-worker.info-1fa.workers.dev/userdata', {
                headers: {
                    'Authorization': token
                }
            });

            const data = await response.json();
            setBalance(data.balance);
            setKeys(data.keys);
            setTotalKeys(data.keys.length);
        };

        fetchData();
    }, [token]);

    const resetHwid = (key) => {
        alert(`HWID for key ${key} reset.`);
    };

    return (
        <div>
            <h2>Welcome, {username}</h2>
            <p>Balance: ${balance}</p>
            <p>Total Keys: {totalKeys}</p>

            <h3>Your Keys</h3>
            <table>
                <thead>
                    <tr>
                        <th>Key</th>
                        <th>Date Bought</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {keys.map(key => (
                        <tr key={key.key}>
                            <td>{key.key}</td>
                            <td>{key.date}</td>
                            <td>
                                <button onClick={() => resetHwid(key.key)}>Reset HWID</button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}

export default Dashboard;